import { useEffect, useState } from 'react';
import { scrollbarWidth } from '@xobotyi/scrollbar-width';

const useScrollbarWidth = () => {
  const [sbw, setSbw] = useState(scrollbarWidth());

  // this needed to ensure the scrollbar width in case hook called before the DOM is ready
  useEffect(function () {
    if (typeof sbw !== 'undefined') {
      return;
    }
    var raf = requestAnimationFrame(function () {
      setSbw(scrollbarWidth());
    });
    return function () {
      return cancelAnimationFrame(raf);
    };
  }, []);
  return sbw;
};

export default useScrollbarWidth;

import React, { Fragment, useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Helmet } from 'react-helmet';
import useScrollbarWidth from '@/hooks/useScrollbarWidth';
import { isBrowser } from '@/utils/common';
import { initAos, toggleAnim } from '@/utils/aos';
import useWindowWidth from '@/hooks/useWindowWidth';

// global app styles
import '@/scss/theme/base/main.scss';
import '@/scss/theme/base/utilities.scss';
import '@/scss/theme/base/vendors.scss';

// base layout
const Layout = ({ children }) => {
  const scrollbarWidth = useScrollbarWidth();
  const windowWidth = useWindowWidth();
  // init animations
  useEffect(() => {
    initAos();
    setTimeout(() => {
      dispatchEvent(new Event('scroll')); // fix react-stickynode issue
    }, 1000);
  }, [children]);

  useEffect(() => {
    toggleAnim();
  }, [windowWidth]);
  return (
    <Fragment>
      <Helmet
        bodyAttributes={{
          class: 'c-compensate-for-scrollbar',
        }}
      >
        {isBrowser()
          ? scrollbarWidth !== undefined && (
              <style>{`
              .is-active-padding-fix .c-compensate-for-scrollbar {
                padding-right: ${scrollbarWidth}px;
                overflow: hidden;
                margin-right: 0!important;
              }
            `}</style>
            )
          : null}
      </Helmet>
      <AnimatePresence exitBeforeEnter>
        <div className="c-layout">{children} </div>
      </AnimatePresence>
    </Fragment>
  );
};

export default Layout;

import aos from 'aos/dist/aos';
import debounce from 'lodash.debounce';
// import 'aos/dist/aos.css';
export const animBreakpoint = 768;
export const aosConfig = {
  // Global settings:
  disable: animBreakpoint, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
  initClassName: 'is-ready', // class applied after initialization ('aos-init')
  animatedClassName: 'is-anim', // class applied on animation ('aos-animate')
  useClassNames: true, // if true, will add content of `data-aos` as classes on scroll (false)
  disableMutationObserver: false, // disables automatic mutations' detections (advanced)
  debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  // offset: 120, // offset (in px) from the original trigger point
  // delay: 100, // values from 0 to 3000, with step 50ms (0)
  duration: 600, // values from 0 to 3000, with step 50ms (400)
  easing: 'ease', // default easing for AOS animations
  once: false, // whether animation should happen only once - while scrolling down (false)
  mirror: false, // whether elements should animate out while scrolling past them
  anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
};

export const getAosController = () => {
  return {
    objects: document.querySelectorAll('[data-anim-aos]'), // HTML DOM objects with aos
    minWidth: animBreakpoint,
    insertAos: function () {
      for (let i = 0; i < this.objects.length; i++) {
        const obj = this.objects[i];
        const attr = obj.getAttribute('data-anim-aos');
        obj.setAttribute('data-aos', attr);
      }
    },
    deleteAos: function () {
      for (let i = 0; i < this.objects.length; i++) {
        const obj = this.objects[i];
        obj.removeAttribute('data-aos');
      }
    },
    init: function () {
      if (window.innerWidth >= this.minWidth) this.insertAos();
    },
    refresh: function () {
      const _this = this;
      let tId;
      const debounced = debounce(function () {
        if (window.innerWidth >= _this.minWidth) {
          _this.insertAos();
        } else {
          _this.deleteAos();
        }
        clearTimeout(tId);
        tId = setTimeout(aos.refreshHard, 200); // it will refresh aos position
      }, 50);
      window.addEventListener('resize', debounced, false);
      window.addEventListener('orientationchange', debounced, false);
    },
  };
};

export const initAos = () => {
  if (typeof window === 'undefined') return;
  const aosController = getAosController();
  // Prepare DOM
  aosController.init(); // this will init aosController
  aosController.refresh(); // this will refresh aos on every resize event

  // aos animations initialisation setup
  aos.init(aosConfig);
};

export const toggleAnim = () => {
  const bp = Modernizr.mq(`(min-width: ${animBreakpoint}px)`);
  // const isAnimScriptLoaded = $('#site-animations').attr('data-enabled');
  // console.log('isAnimScriptLoaded', isAnimScriptLoaded);
  // if (isAnimScriptLoaded) {
  //   $('html').addClass('is-anim-loaded');
  // }
  if (bp) {
    document.documentElement.classList.add('is-anim-ready');
    aos.refresh();
  } else {
    document.documentElement.classList.remove('is-anim-ready');
  }
};

import { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';
import { isBrowser } from '@/utils/common';

const getWindowWidth = () =>
  isBrowser()
    ? window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    : 0;

const useWindowWidth = (debounceMs = 100) => {
  const [windowWidth, setWindowWidth] = useState(getWindowWidth());

  useEffect(() => {
    if (!isBrowser()) {
      return false;
    }

    let isMounted = true;

    function handleResize() {
      if (isMounted) {
        setWindowWidth(getWindowWidth());
      }
    }

    const debounced = debounce(handleResize, debounceMs);

    window.addEventListener('resize', debounced);
    return () => {
      isMounted = false;
      window.removeEventListener('resize', debounced);
    };
  }, [debounceMs]);

  return windowWidth;
};

export default useWindowWidth;
